<template>
<div>
  <div class="flex flex-col">
    <el-row class="box-card flex flex-col bg-white">
      <el-col :span="24" class="h-20 py-4 flex justify-between items-center bg-white border-b-2">
        <el-col :span="18" class="flex px-4">
          <el-select
            v-model="searchDepartment"
            filterable
            class="select__department py-2"
            placeholder="Filtrar por Unidade"
            size="large"
          >
            <el-option
              v-for="item in departments"
              :key="item.id"
              :label="item.nome + ' - ' + item.sigla"
              :value="item.id"
            />
          </el-select>
          <el-input 
            v-if="accessLevel === 'MANAGER'"
            @input="actionSearchAll"
            v-model="parameterSearchAll"
            class="input__size m-2"
            placeholder="Filtrar por nome / matrícula"
          >
            <template #append>
              <i class="ri-search-line"></i>
            </template>
          </el-input>
        </el-col>
        <el-col v-if="accessLevel === 'MANAGER'" :span="6" class="flex justify-end px-4">
          <el-button type="primary" size="large" @click="modalCreate = true">Criar novo usuário</el-button>
        </el-col>
      </el-col>
      <el-col v-if="loading" :span="24" class="flex flex-col items-start">
        <SkeletonTable />
      </el-col>
      <el-col v-else-if="users.length === 0" class="p-6 bg-white" v-cloak>
        <el-row>
          <el-col class="mt-6 flex flex-col items-center" :span="24">
            <img src="../../assets/table.svg" class="img" />
            <h3 class="text-xl text-blue-600 font-medium mt-2">Utilizem os filtros para iniciar a busca por usuários.</h3>
          </el-col>
        </el-row>
      </el-col>
      <el-col v-else :span="24" class="grid justify-center">
        <el-table
          class="border-1 box-card"
          :data="users.users"
          :empty-text="emptyText"
        >
          <el-table-column label="Unidade" width="100px">
            <template #default="scope">
              <span>{{ scope.row.unidade_sigla || scope.row.department }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Matrícula" width="120px">
            <template #default="scope">
              <span>{{ scope.row.employeeid }}</span>
            </template>
          </el-table-column>
          <el-table-column label="CPF" width="120px">
            <template #default="scope">
              <span>{{ scope.row.username }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Posto/Graduação" width="150px">
            <template #default="scope">
              <span>{{ scope.row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Nome" width="300px">
            <template #default="scope">
              <b>{{ scope.row.name }}</b>
            </template>
          </el-table-column>
          <el-table-column label="Divisão" width="120px">
            <template #default="scope">
              <span>{{ scope.row.physicaldeliveryofficename }}</span>
            </template>
          </el-table-column>
          <el-table-column label="E-mail" width="250px">
            <template #default="scope">
              <span>{{ scope.row.email_particular }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Ativo" width="100px">
            <template #default="scope">
              <el-switch
                v-model="scope.row.active"
                v-on:click="actionSwitchActive(Number(scope.row.id), scope.row.displayname, scope.row.active)"
                :active-value="1"
                :inactive-value="0"
              />
            </template>
          </el-table-column>
          <el-table-column label="Ações" width="200px" align="right">
            <template #default="scope">
              <el-row class="d-flex justify-end">
                <el-button type="success" size="small" @click="handleEdit(scope.row)">Editar</el-button>
                <el-button v-if="userMaster" type="danger" size="small" @click="deleteUser(Number(scope.row.id))">Excluir</el-button>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row v-if="users.count > take" class="py-8 bg-white" justify="center">
      <Pagination @CurrentChange="changePagination" :pageSize="take" :total="users.count" />
    </el-row>
  </div>
  <el-drawer
    v-if="drawerEdit.user"
    v-model="drawerEdit.view"
    @open="openDrawerEdit(drawerEdit.user)"
    @close="closeDrawer"
    @closed="closedDrawerEdit"
    :show-close="false"
    size="550px"
  >
    <template #header>
      <el-row>
        <el-col>
          <div>
            <h2 class="text-lg font-bold text-blue-500">{{ templateNameDrawer }}</h2>
            <h3 class="text-muted">{{ drawerEdit.user.unidade_sigla }}</h3>
          </div>
          <el-button
            v-if="drawerEdit.statusRegistration"
            @click="drawerEdit.viewUpdatePolice = true"
            class="mt-4 w-full"
            type="danger"
            size="large"
            >
            <i class="mr-2 ri-upload-cloud-line"></i>
            <span>O usuário está com o cadastro desatualizado, clique aqui para atualiza-lo.</span>
          </el-button>
          <div v-if="drawerEdit.updatePolice">
            <el-drawer
              v-if="drawerEdit.updatePolice.unidade_policiais_unidade_idTounidade"
              v-model="drawerEdit.viewUpdatePolice"
              :append-to-body="true"
              @closed="drawerEdit.viewUpdatePolice = false"
              show-close
              size="400px"
            >
              <template #header>
                <el-row>
                  <el-col :span="24">                  
                    <h3 class="text-muted">{{ `Nova unidade: ${drawerEdit.updatePolice.unidade_policiais_unidade_idTounidade.sigla}` }}</h3>
                    <h3 class="text-muted">{{ `${drawerEdit.updatePolice.quadro.sigla} ${drawerEdit.updatePolice.posto_graduacao.sigla} ${drawerEdit.updatePolice.nome_de_guerra}` }}</h3>
                    <h3 class="text-muted">{{ `Matrícula: ${labelEmployeeid(drawerEdit.user.employeeid)}` }}</h3>
                  </el-col>
                </el-row>
              </template>
              <el-form label-position="top">
                <el-row v-if="groupDcc.includes(drawerEdit.updatePolice.unidade_id)">
                  <el-col :span="24">
                    <el-form-item label="Divisão">
                      <el-select
                        v-model="drawerEditDivision"
                        class="w-full"
                        placeholder="Selecione a divisão"
                        size="large"
                      >
                        <el-option
                          v-for="item in drawerEdit.updatePolice.unidade_policiais_unidade_idTounidade.divisao"
                          :key="item.id"
                          :label="item.sigla"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="Seção">
                      <el-select
                        v-model="drawerEdit.section.selected"
                        :disabled="drawerEdit.section.list.length === 0"
                        class="w-full"
                        placeholder="Selecione a seção"
                        size="large"
                      >
                        <el-option
                          v-for="section in drawerEdit.section.list"
                          :key="section.id"
                          :label="section.sigla"
                          :value="section.id"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col class="flex justify-end my-4" :span="24">
                    <el-button
                      @click="updateRegisterPolice"
                      :disabled="groupDcc.includes(drawerEdit.updatePolice.unidade_id) ? !drawerEdit.section.selected : false"
                      :loading="drawerEdit.loadingUpdatePolice"
                      class="w-full"
                      type="primary"
                      size="large"
                    >Atualizar</el-button>
                  </el-col>
                </el-row>
              </el-form>
            </el-drawer>
          </div>
        </el-col>
      </el-row>
    </template>
    <div>
      <el-row class="bg-blue-10 mb-5">
        <h2 class="font-medium text-xl text-white px-2">Dados Pessoais</h2>
      </el-row>
      <el-row class="my-4">
        <el-col :span="24">
          <el-form label-position="top">
            <el-form-item label="E-mail">
              <el-input
                v-model="drawerEdit.user.email_particular"
                :disabled="drawerEdit.loadingUpdatePersonalData || drawerEdit.statusRegistration"
              >
                <template #prepend>
                  <i class="ri-mail-line"></i>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="Telefone">
              <el-input
                v-model="drawerEdit.user.telefone_numero"
                :disabled="drawerEdit.loadingUpdatePersonalData || drawerEdit.statusRegistration"
                v-mask="['#####-####', '(##)#####-####', '(##)####-####', '####-####']"
              >
                <template #prepend>
                  <i class="ri-phone-line"></i>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-divider />
        <el-col class="flex justify-end mb-4" :span="24">
          <el-col :span="12">
            <el-button
              @click="updatePersonalData"
              :loading="drawerEdit.loadingUpdatePersonalData"
              :disabled="drawerEdit.statusRegistration"
              class="w-full"
              type="primary"
              size="large"
            >Atualizar dados pessoais</el-button>
          </el-col>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row class="bg-blue-10">
        <h2 class="font-medium text-xl text-white px-2">Permissões</h2>
      </el-row>
      <el-skeleton v-if="permissionsLoading" style="width:100%" animated>
        <template #template>
          <div style="padding: 14px">
            <el-skeleton-item variant="h3" style="width: 100%" />
            <div v-for="n in 4" :key="n"
              style="
                display: flex;
                align-items: center;
                justify-items: space-between;
                margin-top: 16px;
                height: 16px;
              "
            >
              <el-skeleton-item variant="text" style="margin-right: 16px" />
              <el-skeleton-item variant="text" style="width: 30%" />
            </div>
          </div>
        </template>
      </el-skeleton>
      <el-row v-else-if="userGroupDcc" class="mt-4 flex justify-end">
        <el-col class="mb-4" :span="12">
          <el-button
            @click="permissionsModal = true"
            :disabled="drawerEdit.statusRegistration"
            class="w-full"
            type="primary"
            size="large"
          >Atualizar permissões</el-button>
        </el-col>
      </el-row>
      <el-row v-else class="mt-4">
        <el-col v-for="permission in permissionsList" :key="permission.id">
          <el-switch
            :disabled="permission.id === 63 || drawerEdit.statusRegistration"
            v-model="permission.value"
            :active-text="permission.name"
            @click="menuPermissionsPmdfAll(drawerEdit.user.id, permission)"  
          />
        </el-col>
      </el-row>
    </div>
  </el-drawer>
  <Modal 
    :visible="permissionsModal"
    @close="closeModalPermissions"
  >
    <template v-slot:header>
      <div>
        <h3 class="font-semibold text-gray-500">{{ templateNameDrawer }}</h3>
      </div>
    </template>
    <el-col :span="24">
      <el-row>
        <h2 class="w-full font-medium text-base text-white bg-green-500 px-2 py-1 mb-2">Permissões Ativas:</h2>
        <ul>
          <li class="mb-2" v-for="group in memberOfLdap" :key="group">
            <el-tag v-if="group" size="large" type="success">
              <span class="text-base">
                {{ group }}
              </span>
            </el-tag>
          </li>
        </ul>
      </el-row>
      <el-row>
        <h2 class="w-full font-medium text-base text-white bg-blue-500 px-2 py-1 mb-2">Alterar Permissões:</h2>
        <div class="w-full flex flex-col items-center">
          <PermissionsList
            @savePermissions="updatePermissionsModal"
            @close="permissionsModal = false"
            :userDB="drawerEdit.user"
            :userLdap="dataOfUserLdap"
            :infoUser="drawerEdit.user"
            :accessUser="{accessLevel, roles: perfilUser, parent: 'abaDriwer'}"
          />
        </div>
      </el-row>
    </el-col>
  </Modal>
  <ModalCreateUser 
    v-if="departments.length"
    @createSuccess="createUser"
    @close="modalCreate = false"
    :visible="modalCreate"
    :departments="departments"
    :accessUser="{accessLevel, roles: perfilUser, parent: 'listUser'}"
  />
</div>
</template>

<script>
import Pagination from './Pagination.vue'
import SkeletonTable from './SkeletonTable.vue'
import ModalCreateUser from './ModalCreateUser.vue'
import Modal from '@/components/Modal.vue'
import PermissionsList from '@/components/permission/PermissionsList.vue'
import { userLdap } from '@/services/ldap/user.ldap.service'
import { permissionLdap } from '@/services/ldap/permission.ldap.service'
import UserService from "@/services/user.service"
import DepartmentService from "@/services/department.service"
import PermissionService from "@/services/permission.service"
import { notificationSuccess } from '@/utils/notification'
import { permissionAccess } from '@/utils/geral'
import { GROUP_DCC, Keys } from '@/const'

export default {
  name: 'ListUser',
  components: {
    Pagination,
    ModalCreateUser,
    SkeletonTable,
    Modal,
    PermissionsList
  },
  props: ['perfilUser'],
  data: () => {
    return {
      users: [],
      emptyText: 'Nenhum usuário selecionado',
      skip: 0,
      take: 20,
      departments: [],
      count: null,
      searchDepartment: null,
      parameterSearchAll: null,
      loading: false,
      modalCreate: false,
      drawerEditDivision: null,
      drawerEdit: {
        loadingUpdatePersonalData: false,
        user: null,
        view: false,
        division: {
          list: []
        },
        section: {
          selected: null,
          list: []
        },
        viewUpdatePolice: false,
        updatedActive: false,
        statusRegistration: false,
        loadingUpdatePolice: false,
        updatePolice: []
      },
      permissionUserEdit: null,
      permissionsList: [
        {
          id: 34,
          name: 'Comandante',
          value: null
        },
        {
          id: 53,
          name: 'Encarregado',
          value: null
        },
        {
          id: 63,
          name: 'Escrivão (Permissão concedida apenas pelo Encarregado)',
          value: null
        },
        {
          id: 25,
          name: 'SSJD',
          value: null
        },
        {
          id: 50,
          name: 'Seção Administrativa',
          value: null
        },
      ],
      infoLdap: null,
      permissionsUpdateDelete: [
        {
          id: 34,
          group: 'pmdf_all.comandantes'
        },
        {
          id: 25,
          group: 'pmdf_all.sjds'
        },
      ],
      permissionsModal: false,
      permissionsLoading: true
    }
  },
  computed:{
    templateNameDrawer() {
      return `${this.drawerEdit.user.title} ${(this.drawerEdit.user.nome_guerra || this.drawerEdit.user.name)}`
    },
    userMaster() {
      return this.$store.state.master.includes(this.$store.state.userLogged.id)
    },
    groupDcc() {
      return GROUP_DCC
    },
    userGroupDcc() {
      if(this.drawerEdit.updatePolice) return this.groupDcc.includes(Number(this.drawerEdit.updatePolice.unidade_id))
      if(this.drawerEdit.user) return this.groupDcc.includes(Number(this.drawerEdit.user.unidade_id))
      return false
    },
    dataOfUserLdap() {
      return this.infoLdap
    },
    memberOfLdap() {
      if(this.infoLdap.memberOf) return this.infoLdap.memberOf.flatMap((el) => {
        const permission = el.split(',')
        return Keys[permission[0].slice(3)]
      }).sort()
      return null
    },
    accessLevel() {
      return permissionAccess(this.perfilUser)
    }
  },
  created() {
    this.userService = new UserService()
    this.departmentService = new DepartmentService()
    this.permissionService = new PermissionService()
  },
  async mounted() {
    await this.listDepartments()
  },
  methods: {
    createUser() {
      this.parameterSearchAll !== null ?
        this.actionSearchAll() :
        this.searchDepartment !== null &&
        this.listUsers(this.skip, this.take)
    },
    async listUsers(skip, take) {
      this.loading = true
      return await this.userService
      .perDepartment({ skip, take, idDepartment: this.searchDepartment })
      .then((data) => {
        this.users = data
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => this.loading = false)
    },
    async listDepartments() {
      await this.departmentService
        .all()
        .then((data) => {
          this.departments = this.accessLevel === 'MANAGER'
            ? data 
            : data.map((dep) => {
              const { id } = dep
              if(this.groupDcc.includes(id)) return dep
            }).filter((e) => e)
        })
        .catch((err) => {
          console.log(err);
        })
    },
    actionSearchAll() {
      if (this.parameterSearchAll.length >= 3) {
        this.loading = true
        this.userService
        .search({ args: this.parameterSearchAll.toUpperCase() })
        .then((data) => {
        this.users = data
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => this.loading = false)
      }
    },
    changePagination(e) {
      if (e === 1) this.skip = 0
      else this.skip = this.take * (e - 1)
      this.listUsers(this.skip, this.take)
    },
    async handleEdit(ev) {
      this.drawerEdit.user = ev
      await this.permissionService
        .user({ idUser: ev.id })
        .then((data) => {
          data && data.forEach(permission => {
            this.permissionsActions(permission.id)
          });
        })
        .catch((err) => {
          console.log(err);
        })
      this.drawerEdit.view = true
    },
    async deleteUser(id) {
      await this.userService
        .delete(id)
        .then(() => {
          notificationSuccess('Sucesso!', `O usuário foi excluido com sucesso!`, 5000, false, 'top-left')
          this.listUsers(this.skip, this.take)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    permissionsActions(id) {
      this.permissionsList = this.permissionsList.map(permission => {
        if(permission.id === id) {
          return {
            ...permission,
            value: true
          }
        }
        return permission
      })
    },
    async menuPermissionsPmdfAll(idUser, permission) {
      if (this.drawerEdit.updatedActive && permission.value) {
        await this.permissionService
          .add({ idUser, idRole: permission.id })
          .then(() => {
            notificationSuccess('Sucesso!', `A permissão de ${permission.name} foi atualizada com sucesso!`, 5000, false, 'top-left')
          })
          .catch((err) => {
            console.log(err)
          })
      }
      if (this.drawerEdit.updatedActive && !permission.value) {
        await this.permissionService
          .delete({ idUser, idRole: permission.id })
          .then(() => {
            notificationSuccess('Sucesso!', `A permissão de ${permission.name} foi atualizada com sucesso!`, 5000, false, 'top-left')
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    labelEmployeeid(id) {
      return id.replace(/^0*(\d+)(\d)$/, '$1/$2')
    },
    async openDrawerEdit(user) {
      this.drawerEdit.updatedActive = true
      await this.statusRegistration(user.employeeid)
      const { attributes, objectName } = await userLdap.getUserLdap(user.employeeid)
      const attibutesRefactor = attributes.reduce((total, item) => {
        if(!total.length) return [{ [total.type]: [...total.vals] , [item.type]: [...item.vals] }]
        if(total.length) return [{ ...total[0], [item.type]: [...item.vals] }]
      })
      this.infoLdap = { ...attibutesRefactor[0], objectName }
      this.permissionsLoading = false

    },
    async statusRegistration(employeeid) {
      return await this.userService
        .statusRegistration({ employeeid })
        .then(({ status, tablePolices }) => {
          this.permissionsLoading = false
          this.drawerEdit.statusRegistration = status
          this.drawerEdit.updatePolice = tablePolices
        })
        .catch((err) => {
          console.log(err);
        })
    },
    async updatePersonalData() {
      this.drawerEdit.loadingUpdatePersonalData = true
      if (this.drawerEdit.user.id) {
        await this.userService
          .update({
            id: Number(this.drawerEdit.user.id),
            data: {
              email_particular: this.drawerEdit.user.email_particular,
              telefone_numero: this.drawerEdit.user.telefone_numero
            }
          })
          .then(() => {
            notificationSuccess('Sucesso!', `Os dados pessoais foram atualizados!`, 5000, false, 'top-left'
            )
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => this.drawerEdit.loadingUpdatePersonalData = false)
      }
    },
    async updateRegisterPolice() {
      this.drawerEdit.loadingUpdatePolice = true
      const rolesIds = [25, 34]

      if (this.groupDcc.includes(this.drawerEdit.updatePolice.unidade_id)) {
        
        const options = {
          dn: this.infoLdap.objectName,
          cn: `${this.drawerEdit.updatePolice.posto_graduacao.sigla} ${this.drawerEdit.updatePolice.quadro.sigla} ${this.drawerEdit.updatePolice.nome_de_guerra}`,
          unity: this.drawerEdit.updatePolice.unidade_id
        }

        if(this.infoLdap.memberOf) {
          const permissionsDelete = this.infoLdap.memberOf.flatMap((el) => {
            const permission = el.split(',')
            return this.permissionsUpdateDelete.filter((e) => e.group === permission[0].slice(3))
          })
  
          if (permissionsDelete.length > 0) Promise.all(
            permissionsDelete.forEach(({ group }) => {
              permissionLdap.delPermission(`dn=${options.dn}&group=${group}`)
            })
          )
        }
        //await userLdap.updateUserLdap({dn: options.dn, cn: options.cn })
        await userLdap.directoryUpdateLdap({dn: options.dn, cn: options.cn, unity: options.unity})
      }

      this.permissionsList.forEach(async (permission) => {
        if (rolesIds.includes(permission.id) && permission.value) await this.permissionService.delete({ idUser: this.drawerEdit.user.id, idRole: permission.id })
      })
      
      await this.userService
        .updateRegistration({
          cpf: this.drawerEdit.updatePolice.cpf,
          division: this.drawerEdit.division.list ? this.drawerEdit.division.list : {id: null, sigla: null },
          section: this.drawerEdit.section.selected ? { id: this.drawerEdit.section.selected } : { id: null}
        })
        .then((data) => {
          notificationSuccess('Sucesso!', `${`O usuário ${data.displayname} foi atualizado.`}`, 5000, false, 'top-left'
          )
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          if (this.searchDepartment) this.listUsers(0, this.take)
          else if (this.parameterSearchAll) this.actionSearchAll()
          this.drawerEdit.loadingUpdatePolice = false
          this.drawerEdit.viewUpdatePolice = false
          this.drawerEdit.view = false
        })
    },
    closeDrawer() {
      this.permissionsList = this.permissionsList.map(permission => {
        return { ...permission, value: null }
      })
      this.permissionsLoading = true
    },
    closedDrawerEdit() {
      this.drawerEdit.view = false
      this.drawerEdit.updatedActive = false
      this.drawerEdit.statusRegistration = false
    },
    async actionSwitchActive(id, displayname, active) {
      await this.userService
        .update({ id, data: { active: active ? true : false } })
        .then(async (data) => {
          if(this.groupDcc.includes(data.unidade_id)) return await userLdap.getUserLdap(data.employeeid)
          return 
        })
        .then(async (info) => {
          if(info) await userLdap.updateUserLdap({dn: info.objectName, userAccountControl: active ? 544 : 546 })
          return
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          notificationSuccess('Sucesso!', `${
            active ?
            `O usuário ${displayname} foi ativado.` :
            `O usuário ${displayname} foi desativado.`}`, 5000, false, 'top-left'
          )
        })
    },
    updatePermissionsModal() {
      notificationSuccess('Sucesso!', `As permissões foram atualizadas com sucesso!`, 5000, false)
      this.permissionsModal = false
      this.closeDrawer()
      this.closedDrawerEdit()
    },
    closeModalPermissions() {
      this.permissionsModal = false
      this.closeDrawer()
      this.closedDrawerEdit()
      this.infoLdap = null
    }
  },
  watch: {
    searchDepartment(newValue, oldValue) {
      if (newValue !== oldValue) this.listUsers(0, this.take)
    },
    parameterSearchAll(newValue) {
      if(newValue === '' && this.searchDepartment !== null) this.listUsers(0, this.take)
    },
    drawerEditDivision(newValue) {
      this.drawerEdit.updatePolice.unidade_policiais_unidade_idTounidade.divisao.forEach((division) => {
        if (division.id === newValue) {
          this.drawerEdit.section.list = division.secao
          this.drawerEdit.division.list = division
        }
      })
    }
  }
}
</script>

<style scoped>
[v-cloak] {
  display: none;
}
.box-card {
  min-height: 100vh
}
.input__size {
  width: 300px;
}
.select__department {
  width: 200px;
}
.img {
  width: 300px;
  height: 300px;
}
</style>